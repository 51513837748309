export const webLicenses = [
    {
      "name": "@angular/animations@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/cdk@6.4.7",
      "license": "MIT",
      "repo": "https://github.com/angular/material2",
      "url": "https://github.com/angular/material2/raw/master/LICENSE"
    },
    {
      "name": "@angular/common@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/compiler@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/core@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/flex-layout@6.0.0-beta.18",
      "license": "MIT",
      "repo": "https://github.com/angular/flex-layout",
      "url": "https://github.com/angular/flex-layout/raw/master/LICENSE"
    },
    {
      "name": "@angular/forms@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/http@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/platform-browser-dynamic@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/platform-browser@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@angular/router@6.1.9",
      "license": "MIT",
      "repo": "https://github.com/angular/angular",
      "url": "https://github.com/angular/angular"
    },
    {
      "name": "@ng-idle/core@6.0.0-beta.3",
      "license": "Apache-2.0",
      "repo": "https://github.com/HackedByChinese/ng2-idle",
      "url": "https://github.com/HackedByChinese/ng2-idle/raw/master/LICENSE"
    },
    {
      "name": "@ng-idle/keepalive@6.0.0-beta.3",
      "license": "Apache-2.0",
      "repo": "https://github.com/HackedByChinese/ng2-idle",
      "url": "https://github.com/HackedByChinese/ng2-idle/raw/master/LICENSE"
    },
    {
      "name": "@ngx-progressbar/core@5.0.1",
      "license": "MIT",
      "repo": "https://github.com/murhafsousli/ngx-progressbar",
      "url": "https://github.com/murhafsousli/ngx-progressbar"
    },
    {
      "name": "@swimlane/ngx-charts@9.0.0",
      "license": "MIT",
      "repo": "https://github.com/swimlane/ngx-charts",
      "url": "https://raw.githubusercontent.com/swimlane/ngx-charts/master/LICENSE"
    },
    {
      "name": "@types/big.js@4.0.5",
      "license": "MIT",
      "repo": "https://github.com/DefinitelyTyped/DefinitelyTyped",
      "url": "https://github.com/DefinitelyTyped/DefinitelyTyped/raw/master/LICENSE"
    },
    {
      "name": "@types/jquery@3.3.12",
      "license": "MIT",
      "repo": "https://github.com/DefinitelyTyped/DefinitelyTyped",
      "url": "https://github.com/DefinitelyTyped/DefinitelyTyped/raw/master/LICENSE"
    },
    {
      "name": "big.js@5.1.2",
      "license": "MIT",
      "repo": "https://github.com/MikeMcl/big.js",
      "url": "https://github.com/MikeMcl/big.js/raw/master/LICENCE"
    },
    {
      "name": "bootstrap@3.3.7",
      "license": "MIT",
      "repo": "https://github.com/twbs/bootstrap",
      "url": "https://github.com/twbs/bootstrap/raw/master/LICENSE"
    },
    {
      "name": "browser-xml2js@0.4.19",
      "license": "MIT",
      "repo": "https://github.com/mboughaba/browser-xml2js",
      "url": "https://github.com/mboughaba/browser-xml2js/raw/master/LICENSE"
    },
    {
      "name": "core-js@2.5.7",
      "license": "MIT",
      "repo": "https://github.com/zloirock/core-js",
      "url": "https://github.com/zloirock/core-js/raw/master/LICENSE"
    },
    {
      "name": "dateformat@3.0.3",
      "license": "MIT",
      "repo": "https://github.com/felixge/node-dateformat",
      "url": "https://github.com/felixge/node-dateformat/raw/master/LICENSE"
    },
    {
      "name": "jquery@3.3.1",
      "license": "MIT",
      "repo": "https://github.com/jquery/jquery",
      "url": "https://github.com/jquery/jquery/raw/master/LICENSE.txt"
    },
    {
      "name": "lodash@4.17.11",
      "license": "MIT",
      "repo": "https://github.com/lodash/lodash",
      "url": "https://github.com/lodash/lodash/raw/master/LICENSE"
    },
    {
      "name": "moment@2.22.2",
      "license": "MIT",
      "repo": "https://github.com/moment/moment",
      "url": "https://github.com/moment/moment/raw/master/LICENSE"
    },
    {
      "name": "newtonsoft.Json",
      "license": "MIT",
      "repo": "https://github.com/JamesNK/Newtonsoft.Json",
      "url": "https://github.com/JamesNK/Newtonsoft.Json/blob/master/LICENSE.md"
    },
    {
      "name": "ng2-daterangepicker@2.0.12",
      "license": "MIT",
      "repo": "https://github.com/evansmwendwa/ng2-daterangepicker",
      "url": "https://github.com/evansmwendwa/ng2-daterangepicker"
    },
    {
      "name": "ng2-pdf-viewer@5.2.3",
      "license": "MIT",
      "repo": "git+https://vadimdez@github.com/VadimDez/ng2-pdf-viewer",
      "url": "git+https://vadimdez@github.com/VadimDez/ng2-pdf-viewer"
    },
    {
      "name": "ng2-validation@4.2.0",
      "license": "MIT",
      "repo": "https://github.com/yuyang041060120/angular2-validate",
      "url": "https://github.com/yuyang041060120/angular2-validate/raw/master/LICENSE"
    },
    {
      "name": "ngx-bootstrap@3.0.1",
      "license": "MIT",
      "repo": "https://github.com/valor-software/ngx-bootstrap",
      "url": "https://github.com/valor-software/ngx-bootstrap/raw/master/LICENSE"
    },
    {
      "name": "ngx-contextmenu@5.0.3",
      "license": "MIT",
      "repo": "https://github.com/isaacplmann/ngx-contextmenu",
      "url": "https://github.com/isaacplmann/ngx-contextmenu/raw/master/LICENSE"
    },
    {
      "name": "ngx-device-detector@1.3.3",
      "license": "MIT",
      "repo": "https://github.com/KoderLabs/ngx-device-detector",
      "url": "https://github.com/KoderLabs/ngx-device-detector"
    },
    {
      "name": "ngx-perfect-scrollbar@6.3.1",
      "license": "MIT",
      "repo": "https://github.com/zefoy/ngx-perfect-scrollbar",
      "url": "https://github.com/zefoy/ngx-perfect-scrollbar/raw/master/LICENSE.md"
    },
    {
      "name": "ngx-scrollbar@2.2.0",
      "license": "MIT",
      "repo": "https://github.com/murhafsousli/ngx-scrollbar",
      "url": "https://github.com/murhafsousli/ngx-scrollbar"
    },
    {
      "name": "ngx-select-ex@3.5.9",
      "license": "MIT",
      "repo": "https://github.com/optimistex/ngx-select-ex",
      "url": "https://github.com/optimistex/ngx-select-ex/raw/master/LICENSE"
    },
    {
      "name": "ngx-smart-modal@7.1.0",
      "license": "MIT",
      "repo": "https://github.com/biig-io/ngx-smart-modal",
      "url": "https://github.com/biig-io/ngx-smart-modal/raw/master/LICENSE"
    },
    {
      "name": "ngx-text-overflow-clamp@0.0.1",
      "license": "MIT",
      "repo": "https://github.com/mbenzenhoefer/ngx-text-overflow-clamp",
      "url": "https://github.com/mbenzenhoefer/ngx-text-overflow-clamp"
    },
    {
      "name": "ngx-toastr@8.10.2",
      "license": "MIT",
      "repo": "https://github.com/scttcper/ngx-toastr",
      "url": "https://github.com/scttcper/ngx-toastr/raw/master/LICENSE"
    },
    {
      "name": "ngx-virtual-scroller@1.0.6",
      "license": "MIT",
      "repo": "https://github.com/rintoj/ngx-virtual-scroller",
      "url": "https://github.com/rintoj/ngx-virtual-scroller/raw/master/LICENSE"
    },
    {
      "name": "rxjs@6.3.3",
      "license": "Apache-2.0",
      "repo": "https://github.com/reactivex/rxjs",
      "url": "https://github.com/reactivex/rxjs/raw/master/LICENSE.txt"
    },
    {
      "name": "zone.js@0.8.26",
      "license": "MIT",
      "repo": "https://github.com/angular/zone.js",
      "url": "https://github.com/angular/zone.js/raw/master/LICENSE"
    },
    {
      "name": "fs-extra@7.0.1",
      "license": "MIT",
      "repo": "https://github.com/jprichardson/node-fs-extra",
      "url": "https://github.com/jprichardson/node-fs-extra/raw/master/LICENSE"
    },
    {
      "name": "git-describe@4.0.3",
      "license": "MIT",
      "repo": "https://github.com/tvdstaaij/node-git-describe",
      "url": "https://github.com/tvdstaaij/node-git-describe"
    },
    {
      "name": "hashids@1.2.2",
      "license": "MIT",
      "repo": "https://github.com/ivanakimov/hashids.js",
      "url": "https://github.com/ivanakimov/hashids.js/raw/master/LICENSE"
    },
    {
      "name": "ngx-mask@7.3.6",
      "license": "MIT",
      "repo": "https://github.com/NepipenkoIgor/ngx-mask",
      "url": "https://github.com/NepipenkoIgor/ngx-mask/raw/master/LICENSE"
    }
]

export const mobileLicenses = [
    {
      "name": "@target-corp/react-native-svg-parser@1.0.6",
      "license": "MIT",
      "repo": "https://raw.githubusercontent.com/target/react-native-svg-parser/master/LICENSE.txt",
      "url": "https://raw.githubusercontent.com/target/react-native-svg-parser/master/LICENSE.txt"
    },
    {
      "name": "big.js@5.2.1",
      "license": "MIT",
      "repo": "https://github.com/MikeMcl/big.js",
      "url": "https://github.com/MikeMcl/big.js/raw/master/LICENCE"
    },
    {
      "name": "dateformat@3.0.3",
      "license": "MIT",
      "repo": "https://github.com/felixge/node-dateformat",
      "url": "https://github.com/felixge/node-dateformat/raw/master/LICENSE"
    },
    {
      "name": "immutable@3.8.2",
      "license": "MIT",
      "repo": "https://github.com/facebook/immutable-js",
      "url": "https://github.com/facebook/immutable-js/raw/master/LICENSE"
    },
    {
      "name": "memoize-one@4.0.2",
      "license": "MIT",
      "repo": "https://github.com/alexreardon/memoize-one",
      "url": "https://github.com/alexreardon/memoize-one/raw/master/LICENSE"
    },
    {
      "name": "password-validator@4.1.1",
      "license": "MIT",
      "repo": "https://github.com/tarunbatra/password-validator",
      "url": "https://github.com/tarunbatra/password-validator/raw/master/LICENSE"
    },
    {
      "name": "query-string@6.2.0",
      "license": "MIT",
      "repo": "https://github.com/sindresorhus/query-string",
      "url": "https://github.com/sindresorhus/query-string/raw/master/license"
    },
    {
      "name": "react-native-bcrypt@2.4.0",
      "license": "MIT",
      "repo": "https://github.com/dcodeIO/bcrypt.js",
      "url": "https://github.com/dcodeIO/bcrypt.js/raw/master/LICENSE"
    },
    {
      "name": "react-native-collapsible@1.4.0",
      "license": "MIT",
      "repo": "git+https://oblador@github.com/oblador/react-native-collapsible",
      "url": "git+https://oblador@github.com/oblador/react-native-collapsible/raw/master/LICENSE"
    },
    {
      "name": "react-native-confirmation-code-input@1.0.4",
      "license": "MIT",
      "repo": "https://github.com/ttdung11t2/react-native-confirmation-code-input",
      "url": "https://github.com/ttdung11t2/react-native-confirmation-code-input/raw/master/LICENSE"
    },
    {
      "name": "react-native-content-loader@0.1.6",
      "license": "MIT",
      "repo": "https://github.com/danilowoz/react-content-loader",
      "url": "https://github.com/danilowoz/react-content-loader/blob/master/LICENSE"
    },
    {
      "name": "react-native-datepicker@1.7.2",
      "license": "MIT",
      "repo": "https://github.com/xgfe/react-native-datepicker",
      "url": "https://github.com/xgfe/react-native-datepicker/raw/master/LICENSE"
    },
    {
      "name": "react-native-deep-linking@2.1.0",
      "license": "MIT",
      "repo": "https://github.com/luisfcofv/react-native-deep-linking",
      "url": "https://github.com/luisfcofv/react-native-deep-linking/raw/master/LICENSE"
    },
    {
      "name": "react-native-device-detection@0.2.0",
      "license": "MIT",
      "repo": "https://github.com/m0ngr31/react-native-device-detection",
      "url": "https://github.com/m0ngr31/react-native-device-detection"
    },
    {
      "name": "react-native-easy-toast@1.1.0",
      "license": "MIT",
      "repo": "https://github.com/crazycodeboy/react-native-easy-toast",
      "url": "https://github.com/crazycodeboy/react-native-easy-toast/raw/master/LICENSE"
    },
    {
      "name": "react-native-exception-handler@2.10.2",
      "license": "MIT",
      "repo": "https://github.com/master-atul/react-native-exception-handler",
      "url": "https://github.com/master-atul/react-native-exception-handler/raw/master/LICENSE"
    },
    {
      "name": "react-native-gesture-handler@1.0.12",
      "license": "MIT",
      "repo": "https://github.com/kmagiera/react-native-gesture-handler",
      "url": "https://github.com/kmagiera/react-native-gesture-handler/raw/master/LICENSE"
    },
    {
      "name": "react-native-icon-badge@1.1.3",
      "license": "ISC",
      "repo": "https://github.com/yanqiw/react-native-icon-badge",
      "url": "https://github.com/yanqiw/react-native-icon-badge"
    },
    {
      "name": "react-native-keyboard-spacer@0.4.1",
      "license": "MIT",
      "repo": "https://github.com/Andr3wHur5t/react-native-keyboard-spacer",
      "url": "https://github.com/Andr3wHur5t/react-native-keyboard-spacer/raw/master/LICENSE"
    },
    {
      "name": "react-native-mail@3.0.7",
      "license": "MIT",
      "repo": "https://github.com/chirag04/react-native-mail",
      "url": "https://github.com/chirag04/react-native-mail/raw/master/LICENSE"
    },
    {
      "name": "react-native-modal-dropdown@0.6.2",
      "license": "MIT",
      "repo": "https://github.com/sohobloo/react-native-modal-dropdown",
      "url": "https://github.com/sohobloo/react-native-modal-dropdown/raw/master/LICENSE"
    },
    {
      "name": "react-native-modal@6.5.0",
      "license": "MIT",
      "repo": "https://github.com/react-native-community/react-native-modal",
      "url": "https://github.com/react-native-community/react-native-modal/raw/master/LICENSE.md"
    },
    {
      "name": "react-native-picker-select@4.4.0",
      "license": "MIT",
      "repo": "https://github.com/lawnstarter/react-native-picker-select",
      "url": "https://github.com/lawnstarter/react-native-picker-select/raw/master/LICENSE"
    },
    {
      "name": "react-native-progress@3.5.0",
      "license": "MIT",
      "repo": "https://github.com/oblador/react-native-progress",
      "url": "https://github.com/oblador/react-native-progress/raw/master/LICENSE"
    },
    {
      "name": "react-native-prompt-android@0.3.4",
      "license": "MIT",
      "repo": "https://github.com/shimohq/react-native-prompt-android",
      "url": "https://github.com/shimohq/react-native-prompt-android/raw/master/LICENSE"
    },
    {
      "name": "react-native-push-notification@3.1.2",
      "license": "MIT",
      "repo": "https://github.com/zo0r/react-native-push-notification",
      "url": "https://github.com/zo0r/react-native-push-notification/raw/master/LICENSE"
    },
    {
      "name": "react-native-restart@0.0.7",
      "license": "MIT",
      "repo": "https://github.com/avishayil/react-native-restart",
      "url": "https://github.com/avishayil/react-native-restart/raw/master/LICENSE"
    },
    {
      "name": "react-native-sensitive-info@5.2.6",
      "license": "MIT",
      "repo": "https://github.com/mcodex/react-native-sensitive-info",
      "url": "https://github.com/mcodex/react-native-sensitive-info/raw/master/LICENSE"
    },
    {
      "name": "react-native-spinkit@1.1.1",
      "license": "MIT",
      "repo": "https://github.com/maxs15/react-native-spinkit",
      "url": "https://github.com/maxs15/react-native-spinkit/raw/master/LICENSE"
    },
    {
      "name": "react-native-splash-screen@3.1.1",
      "license": "MIT",
      "repo": "https://github.com/crazycodeboy/react-native-splash-screen",
      "url": "https://github.com/crazycodeboy/react-native-splash-screen/raw/master/LICENSE"
    },
    {
      "name": "react-native-svg@6.5.2",
      "license": "MIT",
      "repo": "https://github.com/magicismight/react-native-svg",
      "url": "https://github.com/magicismight/react-native-svg/raw/master/LICENSE"
    },
    {
      "name": "react-native-touch-id@4.1.0",
      "license": "ISC",
      "repo": "https://github.com/naoufal/react-native-touch-id",
      "url": "https://github.com/naoufal/react-native-touch-id"
    },
    {
      "name": "react-native-vector-icons@5.0.0",
      "license": "MIT",
      "repo": "https://github.com/oblador/react-native-vector-icons",
      "url": "https://github.com/oblador/react-native-vector-icons/raw/master/LICENSE"
    },
    {
      "name": "react-native-walkthrough-tooltip@0.2.0",
      "license": "MIT",
      "repo": "https://github.com/jasongaare/react-native-walkthrough-tooltip",
      "url": "https://github.com/jasongaare/react-native-walkthrough-tooltip/raw/master/LICENSE"
    },
    {
      "name": "react-native-xml2js@1.0.3",
      "license": "MIT",
      "repo": "https://github.com/connected-lab/react-native-xml2js",
      "url": "https://github.com/connected-lab/react-native-xml2js/raw/master/LICENSE"
    },
    {
      "name": "react-native@0.57.5",
      "license": "MIT",
      "repo": "https://github.com/facebook/react-native",
      "url": "https://github.com/facebook/react-native/raw/master/LICENSE"
    },
    {
      "name": "react-navigation-backhandler@1.2.0",
      "license": "MIT",
      "repo": "https://github.com/vonovak/react-navigation-backhandler",
      "url": "https://github.com/vonovak/react-navigation-backhandler/raw/master/LICENSE"
    },
    {
      "name": "react-navigation@3.0.9",
      "license": "BSD-2-Clause",
      "repo": "https://github.com/react-navigation/react-navigation",
      "url": "https://raw.githubusercontent.com/react-navigation/react-navigation/master/packages/stack/LICENSE"
    },
    {
      "name": "react-redux@5.0.7",
      "license": "MIT",
      "repo": "https://github.com/reactjs/react-redux",
      "url": "https://github.com/reactjs/react-redux/raw/master/LICENSE.md"
    },
    {
      "name": "react@16.6.3",
      "license": "MIT",
      "repo": "https://github.com/facebook/react",
      "url": "https://github.com/facebook/react/raw/master/LICENSE"
    },
    {
      "name": "redux-logger@3.0.6",
      "license": "MIT",
      "repo": "https://github.com/theaqua/redux-logger",
      "url": "https://github.com/theaqua/redux-logger/raw/master/LICENSE"
    },
    {
      "name": "redux-persist-filesystem-storage@1.3.1",
      "license": "MIT",
      "repo": "https://github.com/robwalkerco/redux-persist-filesystem-storage",
      "url": "https://github.com/robwalkerco/redux-persist-filesystem-storage"
    },
    {
      "name": "redux-persist-immutable@4.3.1",
      "license": "MIT",
      "repo": "https://github.com/rt2zz/redux-persist-immutable",
      "url": "https://github.com/rt2zz/redux-persist-immutable/raw/master/LICENSE"
    },
    {
      "name": "redux-persist-sensitive-storage@1.0.0",
      "license": "MIT",
      "repo": "https://github.com/CodingZeal/redux-persist-sensitive-storage",
      "url": "https://github.com/CodingZeal/redux-persist-sensitive-storage/raw/master/LICENSE"
    },
    {
      "name": "redux-persist-transform-immutable@5.0.0",
      "license": "MIT",
      "repo": "https://github.com/rt2zz/redux-persist-transform-immutable",
      "url": "https://github.com/rt2zz/redux-persist-transform-immutable"
    },
    {
      "name": "redux-persist@5.10.0",
      "license": "MIT",
      "repo": "https://github.com/rt2zz/redux-persist",
      "url": "https://github.com/rt2zz/redux-persist/raw/master/LICENSE"
    },
    {
      "name": "redux-saga@0.16.2",
      "license": "MIT",
      "repo": "https://github.com/redux-saga/redux-saga",
      "url": "https://github.com/redux-saga/redux-saga/raw/master/LICENSE"
    },
    {
      "name": "redux@4.0.1",
      "license": "MIT",
      "repo": "https://github.com/reduxjs/redux",
      "url": "https://github.com/reduxjs/redux/raw/master/LICENSE.md"
    },
    {
      "name": "reselect@3.0.1",
      "license": "MIT",
      "repo": "https://github.com/reactjs/reselect",
      "url": "https://github.com/reactjs/reselect/raw/master/LICENSE"
    },
    {
      "name": "victory-native@30.4.0",
      "license": "MIT",
      "repo": "https://github.com/FormidableLabs/victory-native",
      "url": "https://github.com/FormidableLabs/victory-native/raw/master/LICENSE.txt"
    },
    {
      "name": "react-native-config@0.11.7",
      "license": "MIT",
      "repo": "https://github.com/luggit/react-native-config",
      "url": "https://github.com/luggit/react-native-config/raw/master/LICENSE"
    }
]